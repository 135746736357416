import { Subscription, SubscriptionTypes } from '@/types';

export class SubscriptionEntity {
  UUID: string;
  userUUID: string;
  subscriptionID: string;
  appLastTransactionDate: Date | string;
  plan: 'corporate';
  type: SubscriptionTypes;
  expirationDate: Date | string;
  renewalOn: Date | string;
  cancelPremiumDate: Date | string;
  upgradePremiumDate: Date | string;
  isPremium: boolean;
  receiptData: string;
  couponCode: '';
  couponStripeID: null;
  pcStripeID: '';
  hasCoupon: boolean;
  offerUUID: string;
  externalID: '';
  trialStartDate: Date | string;
  trialEndDate: Date | string;
  enabled: boolean;
  createdAt: Date | string;
  modifiedAt: Date | string;
  priceID?: string;
  isTrialPremium?: boolean;
  partner?: {
    name: string;
  };

  constructor(subscription: Subscription) {
    this.UUID = subscription.UUID;
    this.userUUID = subscription.userUUID;
    this.subscriptionID = subscription.subscriptionID;
    this.appLastTransactionDate = subscription.appLastTransactionDate;
    this.plan = subscription.plan;
    this.type = subscription.type;
    this.expirationDate = subscription.expirationDate;
    this.renewalOn = subscription.renewalOn;
    this.cancelPremiumDate = subscription.cancelPremiumDate;
    this.upgradePremiumDate = subscription.upgradePremiumDate;
    this.isPremium = subscription.isPremium;
    this.receiptData = subscription.receiptData;
    this.couponCode = subscription.couponCode;
    this.couponStripeID = subscription.couponStripeID;
    this.pcStripeID = subscription.pcStripeID;
    this.hasCoupon = subscription.hasCoupon;
    this.offerUUID = subscription.offerUUID;
    this.externalID = subscription.externalID;
    this.trialStartDate = subscription.trialStartDate;
    this.trialEndDate = subscription.trialEndDate;
    this.enabled = subscription.enabled;
    this.createdAt = subscription.createdAt;
    this.modifiedAt = subscription.modifiedAt;
    this.partner = subscription.partner || null;
    this.isTrialPremium = subscription.isTrialPremium || false;
    this.priceID = subscription.priceID || null;
  }
}
