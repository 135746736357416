import { useEffect, useRef } from 'react';
import Lottie from 'react-lottie-player';

import fileToAudioLoadingAnimation from '@/components/lottie/file to audio.json';

export const FileToAudioLoading = () => {
  const lottieRef = useRef(null);

  useEffect(() => {
    if (!lottieRef.current) return;
    lottieRef.current.play();
  }, []);

  return (
    <Lottie
      ref={lottieRef}
      animationData={fileToAudioLoadingAnimation}
      loop={true}
      className="h-[160px]"
    />
  );
};
