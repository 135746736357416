import classNames from 'classnames';
import { ReactNode } from 'react';

import { Notifications } from '@/modules/notifications/presentation/Notifications';

import { Back } from './Back';
import { SideBar } from './SideBar';

interface LayoutProps {
  withAuth?: boolean;
  hasHistoryBack?: boolean;
  opaqueHeader?: boolean;
  children: ReactNode;
  onClickBack?: () => void;
}

export const Layout = ({
  withAuth = false,
  hasHistoryBack = false,
  opaqueHeader = false,
  children,
  onClickBack,
}: LayoutProps) => {
  return (
    <div id="layout">
      <Notifications />
      <main className="relative">
        <div className={classNames('header', { opaque: opaqueHeader })}>
          {hasHistoryBack && (
            <Back onClick={onClickBack} className="!-left-6 sm:!left-48 md:!left-60 m-2 ms-8" />
          )}
          {!withAuth && <SideBar />}
        </div>

        <div className={classNames('content', { '!pt-40 !pb-0 !ml-0 !md:ml-0': withAuth })}>
          <div>{children}</div>
        </div>
      </main>
    </div>
  );
};
