import { useMutation, useQueryClient } from '@tanstack/react-query';

import { uploadAudio } from '@/modules/audio/domain/resources/uploadAudio';
import { MediaEntity } from '@/modules/medias/domain/entities/MediaEntity';
import { Audio, Media, MediaStatus } from '@/types';

export const useUploadAudio = () => {
  const clientQuery = useQueryClient();

  const {
    mutateAsync: onUploadMedia,
    data: uploadedAudio,
    isLoading,
  } = useMutation({
    mutationKey: ['audio'],
    mutationFn: (data: { file: File }) => uploadAudio(data),
    onMutate: () => {
      const audios = clientQuery.getQueryData<Audio[]>(['audio']) || [];
      clientQuery.setQueryData(
        ['audio'],
        [
          ...audios,
          {
            UUID: 'pending',
            media: new MediaEntity({ status: 'pending' as MediaStatus, duration: 0 } as Media),
          },
        ]
      );
    },
    onSuccess: () => {
      clientQuery.refetchQueries(['audio']);
    },
    onError: (err) => {
      clientQuery.refetchQueries(['audio']);
      console.error(err);
    },
  });

  return { onUploadMedia, uploadedAudio, isLoading };
};
