import { useEffect } from 'react';
import { createPortal } from 'react-dom';

import { Button } from '@/components/Button';
import { Icon } from '@/components/Icon';
import { SubscriptionCode } from '@/modules/subscriptions/presentation/SubscriptionCode';

interface CouponModalProps {
  onClose: () => void;
}

export const CouponModal = ({ onClose }: CouponModalProps) => {
  useEffect(() => {
    document.body.classList.add('modal-open');

    return () => document.body.classList.remove('modal-open');
  }, []);

  return createPortal(
    <div className="modal">
      <div className="modal-bg" onClick={onClose} />
      <div className="modal-container relative p-6 !max-w-xl" aria-modal="true">
        <Button onClick={onClose} iconOnly className="absolute right-0 top-0 !p-4">
          <Icon name="close-off" />
        </Button>
        <div className="modal-content mx-auto my-5">
          <SubscriptionCode onClose={onClose} />
        </div>
      </div>
    </div>,
    document.getElementById('genericPortal')
  );
};
