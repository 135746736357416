import { coreActivateCouponRoute } from '@/modules/subscriptions/domain/routes/subscriptionsRoutes';
import { requestQuery } from '@/services/request';

export const postActivateCoupon = (code: string) =>
  requestQuery<void>({
    url: coreActivateCouponRoute,
    method: 'POST',
    data: {
      receipt: { code },
      type: 'coupon',
    },
  });
