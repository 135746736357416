import { requestQuery } from '@/services/request';
import { coreSubscriptionsRoute } from '@/modules/subscriptions/domain/routes/subscriptionsRoutes';
import { Subscription } from '@/types';
import { SubscriptionEntity } from '../../entities/SubscriptionEntity';

export const getSubscriptions = async () => {
  try {
    const response = await requestQuery<Subscription[]>({
      url: coreSubscriptionsRoute,
    });

    return (response || []).map((d) => new SubscriptionEntity(d));
  } catch (e) {
    return [];
  }
};
