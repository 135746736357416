import { deleteAllSessions } from '@/utils/browserStorage';
import { logout as logoutCorporate } from '@/modules/auth/domain/resources/corporate/logout';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postLogout } from '@/modules/auth/domain/resources/core/postLogout';

export const useLogout = () => {
  const queryClient = useQueryClient();

  const { mutate: logout, isLoading: isLoadingLogout } = useMutation(postLogout, {
    onSuccess: () => {
      queryClient.setQueryData(['user'], null);
      queryClient.invalidateQueries(['user']);
      logoutCorporate('');
      deleteAllSessions();
    },
    onError: () => {
      queryClient.setQueryData(['user'], null);
      queryClient.invalidateQueries(['user']);
      logoutCorporate('');
      deleteAllSessions();
    },
  });

  return { logout, isLoadingLogout };
};
