import classNames from 'classnames';
import Image from 'next/image';
import { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import SwiperCore from 'swiper';
import { SwiperSlide } from 'swiper/react';

import { Button, ButtonTypes } from '@/components/Button';
import { SwiperBlock } from '@/components/carouselsBlocks/SwiperBlock';

const steps = [
  {
    id: 'select',
    title: 'audio.onboarding.select.title',
    description: 'audio.onboarding.select.description',
    image: '/audio-select.svg',
  },
  {
    id: 'edit',
    title: 'audio.onboarding.edit.title',
    description: 'audio.onboarding.edit.description',
    image: '/audio-edit.svg',
  },
  {
    id: 'find',
    title: 'audio.onboarding.find.title',
    description: 'audio.onboarding.find.description',
    image: '/audio-find.svg',
  },
];

interface AudioOnboardingModalProps {
  onClose: () => void;
}

export const AudioOnboardingModal = ({ onClose }: AudioOnboardingModalProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [swiper, setSwiper] = useState<SwiperCore>();
  const { formatMessage } = useIntl();

  useEffect(() => {
    document.body.classList.add('modal-open');

    return () => document.body.classList.remove('modal-open');
  }, []);

  useEffect(() => {
    setCurrentStep(swiper?.activeIndex);
  }, [swiper?.activeIndex]);

  useEffect(() => {
    swiper?.slideTo(currentStep);
  }, [currentStep, swiper]);

  useEffect(() => {
    if (swiper?.activeIndex === steps.length) onClose();
  }, [swiper?.activeIndex, onClose]);

  const handleNextStep = useCallback(() => {
    setCurrentStep((prev) => prev + 1);
    swiper?.slideNext();
  }, [swiper]);

  return createPortal(
    <div className="modal">
      <div className="modal-bg" onClick={onClose} />
      <div className="modal-container relative p-6" aria-modal="true">
        <div className="modal-content mx-auto mt-5">
          <div className="hidden md:grid grid-cols-2 gap-8 min-h-[300px]">
            <div>
              {steps.map((step, index) => (
                <div
                  key={step.id}
                  className={classNames('p-4 cursor-pointer rounded-lg transition-all', {
                    'bg-brand-neutral-800': currentStep === index,
                  })}
                  onClick={() => setCurrentStep(index)}
                >
                  <p
                    className={classNames('font-semibold', {
                      'text-brand-neutral-400': currentStep !== index,
                    })}
                  >
                    {formatMessage({ id: step.title })}
                  </p>
                  <p
                    className={classNames('text-sm text-brand-neutral-400 transition-all', {
                      'opacity-0 h-0': currentStep !== index,
                    })}
                  >
                    {formatMessage({ id: step.description })}
                  </p>
                </div>
              ))}
            </div>
            {steps.map((step, index) => (
              <Image
                key={step.id}
                src={step.image}
                width={400}
                height={320}
                alt={step.title}
                className={classNames({
                  hidden: currentStep !== index,
                })}
              />
            ))}
          </div>

          <SwiperBlock
            onInit={(swiperCore) => {
              setSwiper(swiperCore);
            }}
            onSlideChange={(swiper) => {
              setCurrentStep(swiper.activeIndex);
            }}
            slidesPerView={1}
            spaceBetween={0}
            centeredSlides={true}
            navigation={false}
            loop={false}
            autoplay={false}
            autoHeight
            className="md:hidden text-center !h-full"
          >
            {steps.map((step) => (
              <SwiperSlide key={step.id} className="flex flex-col items-center">
                <Image
                  src={step.image}
                  width={324}
                  height={268}
                  alt={step.title}
                  className="pb-4"
                />
                <p className="font-semibold my-4">{formatMessage({ id: step.title })}</p>
                <p className="text-brand-neutral-400">{formatMessage({ id: step.description })}</p>
              </SwiperSlide>
            ))}
          </SwiperBlock>
          <div className="flex justify-center pt-6">
            <Button
              style="primary"
              type={ButtonTypes.Button}
              fullWidth
              onClick={handleNextStep}
              className="!w-auto"
            >
              {formatMessage({
                id:
                  currentStep === steps.length - 1 ? 'audio.onboarding.convert' : 'global.continue',
              })}
            </Button>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('genericPortal')
  );
};
