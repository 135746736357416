import { useQuery } from '@tanstack/react-query';

import { getAudio } from '@/modules/audio/domain/resources/getAudio';

export const useAudio = (audioUUID: string) => {
  const {
    data: audio,
    isLoading,
    error: audioError,
  } = useQuery({
    queryKey: ['audio', audioUUID],
    queryFn: () => getAudio(audioUUID),
    enabled: !!audioUUID,
    refetchInterval: (data) =>
      ['pending', 'processing'].includes(data?.media?.status) ? 5000 : false,
  });

  return {
    audio,
    isLoading,
    audioError,
  };
};
