import { useQueryClient } from '@tanstack/react-query';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';

import { Button, ButtonTypes } from '@/components/Button';
import { Icon } from '@/components/Icon';
import { InputFile } from '@/components/form/InputFile';
import { FileToAudioLoading } from '@/components/icons/FileToAudioLoading';
import { AUDIO_FILE_MAX_SIZE } from '@/constant/values';
import { useAudio } from '@/modules/audio/hooks/useAudio';
import { useUploadAudio } from '@/modules/audio/hooks/useUploadAudio';
import { audioRoute } from '@/utils/routes';

enum AllowedInputFileType {
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  CSV = 'text/csv',
  EPUB = 'application/epub+zip',
  HTML = '',
  KEY = 'application/vnd.apple.keynote',
  MD = 'text/markdown',
  MOBI = 'application/x-mobipocket-ebook',
  NUMBERS = 'application/vnd.apple.numbers',
  ODP = 'application/vnd.oasis.opendocument.presentation',
  ODT = 'application/vnd.oasis.opendocument.text',
  PAGES = 'application/vnd.apple.pages',
  PDF = 'application/pdf',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  RST = 'text/x-rst',
  RTF = 'text/rtf',
  TSV = 'text/tab-separated-values',
  TXT = 'text/plain',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

const allowedInputFileTypeList = Object.values(AllowedInputFileType) as string[];

interface AudioModalProps {
  onClose: () => void;
}

export const AudioModal = ({ onClose }: AudioModalProps) => {
  const [file, setFile] = useState<File>();
  const [error, setError] = useState<'format' | 'size' | 'unknown'>();
  const { formatMessage } = useIntl();
  const { onUploadMedia, uploadedAudio, isLoading } = useUploadAudio();
  const router = useRouter();
  const { audio } = useAudio(uploadedAudio?.UUID);
  const clientQuery = useQueryClient();

  useEffect(() => {
    document.body.classList.add('modal-open');

    return () => document.body.classList.remove('modal-open');
  }, []);

  const handleOnUploadMedia = async (uploadedFile: File) => {
    if (!uploadedFile) return;
    if (!allowedInputFileTypeList.includes(uploadedFile.type)) {
      setError('format');
      return;
    } else if (uploadedFile.size > AUDIO_FILE_MAX_SIZE) {
      setError('size');
      return;
    }

    setFile(uploadedFile);

    try {
      await onUploadMedia({ file: uploadedFile });
    } catch (error) {
      setFile(undefined);
      setError('unknown');
    }
  };

  const isAudioReady = useMemo(
    () => ['ready', 'error'].includes(audio?.media?.status),
    [audio?.media?.status]
  );

  const handleOnclose = () => {
    clientQuery.removeQueries({ queryKey: ['audio', audio?.UUID] });
    onClose();
  };

  useEffect(() => {
    (async () => {
      if (isAudioReady) {
        setTimeout(() => {
          router.push(audioRoute(audio?.UUID));
          onClose();
        }, 2000);
      }
    })();
  }, [audio?.UUID, isAudioReady, onClose, router]);

  return createPortal(
    <div className="modal">
      <div className="modal-bg" onClick={handleOnclose} />
      <div className="modal-container relative p-6" aria-modal="true">
        <div className="modal-content mx-auto mt-5">
          <div>
            <div className="text-center">
              {file ? (
                isLoading || !isAudioReady ? (
                  <>
                    <h1 className="title-h2">
                      {formatMessage({ id: 'audio.upload.uploading.title' })}
                    </h1>
                    <div className="flex flex-col items-center justify-center gap-2 w-full">
                      <FileToAudioLoading />
                      <p className="text-brand-neutral-400 mt-4">
                        {formatMessage({ id: 'audio.upload.uploading.description' })}
                      </p>
                      <p className="text-brand-neutral-500 text-sm">
                        {formatMessage({ id: 'audio.upload.continue' })}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <h1 className="title-h2">
                      {formatMessage({ id: 'audio.upload.complete.title' })}
                    </h1>
                    <div className="flex flex-col items-center justify-center gap-8 w-full">
                      <Image src="/audio.svg" width={160} height={175} alt="audio-upload" />
                      <p className="text-brand-neutral-400">
                        {formatMessage({ id: 'audio.upload.complete.description' })}
                      </p>
                    </div>
                  </>
                )
              ) : (
                <>
                  <h1 className="title-h2">{formatMessage({ id: 'audio.upload.title' })}</h1>
                  <div className="flex items-center justify-center w-full">
                    <InputFile
                      onChange={handleOnUploadMedia}
                      error={error}
                      className="min-h-[50vh]"
                    >
                      <div className="pointer-events-none flex flex-col items-center justify-center gap-4 p-4">
                        <Icon name="upload" className="stroke-brand-neutral-400 scale-150" />
                        <p className="max-w-[400px] font-semibold">
                          <span className="text-brand-neutral-500">
                            {formatMessage({ id: 'audio.upload.drop' })}
                          </span>
                          <span className="text-secondary-400 ms-1">
                            {formatMessage({ id: 'audio.upload.choose' })}
                          </span>
                          <p className="text-brand-neutral-500 text-sm pt-2">
                            {formatMessage({ id: 'audio.upload.files.format' })}
                          </p>
                        </p>
                        {error && (
                          <p className="text-danger">
                            {formatMessage({ id: `audio.upload.error.${error}` })}
                          </p>
                        )}
                      </div>
                    </InputFile>
                  </div>
                </>
              )}
            </div>

            {isAudioReady && (!file || (file && isLoading)) ? (
              <div className="flex justify-center pt-6">
                <Button
                  style="light"
                  type={ButtonTypes.Button}
                  fullWidth
                  onClick={handleOnclose}
                  className="!w-auto"
                >
                  {formatMessage({
                    id: 'global.cancel',
                  })}
                </Button>
              </div>
            ) : (
              <div className="h-16" />
            )}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('genericPortal')
  );
};
