import { audioByIdRoute } from '@/modules/audio/domain/routes/audioRoutes';
import { CorporateMediaEntity } from '@/modules/medias/domain/entities/CorporateMediaEntity';
import { requestQuery } from '@/services/request';
import { Audio, CorporateMedia } from '@/types';

export const getAudio = async (audioUUID: string) => {
  try {
    const response = await requestQuery<Audio>({ url: audioByIdRoute(audioUUID) });

    return (
      response && {
        ...response,
        media: new CorporateMediaEntity({
          type: 'audio',
          cover: '/audio.svg',
          ...response.media,
        } as unknown as CorporateMedia).toMedia(),
      }
    );
  } catch (e) {
    return null;
  }
};
