import { useMutation, useQueryClient } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { useState } from 'react';

import { useAuth } from '@/modules/auth/hooks/useAuth';
import { useNotifications } from '@/modules/notifications/hooks/useNotifications';
import { getSubscriptions } from '@/modules/subscriptions/domain/resources/core/getSubscriptions';
import { postActivateCoupon } from '@/modules/subscriptions/domain/resources/core/postActivateCoupon';
import { getUser } from '@/modules/user/domain/resources';

export const useActiveCoupon = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { showNotificationError } = useNotifications();
  const [error, setError] = useState<string>();

  // We don't use react-query's loading state because the isLoading state provided by useMutation from @tanstack/react-query
  // only reflects the status of the mutation itself, not any additional asynchronous operations in the onSuccess handler
  const [isLoadingActivateCoupon, setIsLoadingActivateCoupon] = useState(false);

  const { mutateAsync } = useMutation((code: string) => postActivateCoupon(code), {
    onMutate: () => {
      // Set loading to true right before the mutation starts
      setIsLoadingActivateCoupon(true);
    },
    onSuccess: async () => {
      // TODO: When the back-end will use Stripe in a synchronous way,
      // then we won't need anymore to use this interval to check if the user has been updated
      let count = 0;
      const interval = setInterval(async () => {
        const responseUser = await getUser(user?.UUID);
        if (responseUser && responseUser.premium) {
          // If the user has been updated, we need to update the user and subscriptions in the cache
          const subscriptions = await getSubscriptions();

          queryClient.setQueryData(['user'], responseUser);
          queryClient.setQueryData(['user-subscriptions'], subscriptions);

          clearInterval(interval);

          setIsLoadingActivateCoupon(false);
        } else {
          count++;
          if (count === 5) {
            // If the user has not been updated after 5 tries, we show an error
            clearInterval(interval);

            showNotificationError();

            setIsLoadingActivateCoupon(false);
          }
        }
      }, 500);
    },
    onError: async (err: HTTPError) => {
      setIsLoadingActivateCoupon(false);
      const errorMessage = await err.response.json();

      setError(errorMessage?.error);
    },
  });

  return { activateCoupon: mutateAsync, isLoadingActivateCoupon, errorActivateCoupon: error };
};
