import classNames from 'classnames';
import { Form, Formik, FormikProps } from 'formik';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Button, ButtonTypes } from '@/components/Button';
import { Icon } from '@/components/Icon';
import { InputField } from '@/components/form/InputField';
import { LoadingSpinnerIcon } from '@/components/icons/LoadingSpinnerIcon';
import { useAuth } from '@/modules/auth/hooks/useAuth';
import { useActiveCoupon } from '@/modules/subscriptions/hooks/useActivateCoupon';

type FormValueType = {
  code: string | string[];
};

interface SubscriptionCodeProps {
  onClose?: () => void;
}

export const SubscriptionCode = ({ onClose }: SubscriptionCodeProps) => {
  const { user } = useAuth();
  const { formatMessage } = useIntl();
  const { query } = useRouter();

  const { activateCoupon, isLoadingActivateCoupon, errorActivateCoupon } = useActiveCoupon();
  const formikRef = useRef<FormikProps<FormValueType>>();

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleSubmit = async ({ code }: { code: string }) => {
    try {
      await activateCoupon(code);

      setIsFormSubmitted(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isFormSubmitted && !isLoadingActivateCoupon && !errorActivateCoupon && formikRef.current) {
      // Reset the form after the coupon has been activated
      formikRef.current.handleReset();

      setIsFormSubmitted(false);
    }
  }, [isFormSubmitted, isLoadingActivateCoupon, errorActivateCoupon]);

  return (
    <div className="text-center">
      {user?.premium ? (
        <>
          <div className="flex justify-center">
            <div className="h-10 w-10 flex justify-center items-center mb-6 rounded-full bg-brand-neutral-800">
              <Icon name="check" />
            </div>
          </div>
          <h2 className="title-h3">{formatMessage({ id: 'subscriptionCode.success.title' })}</h2>
          <p className="text-sm text-brand-neutral-500">
            {formatMessage({ id: 'subscriptionCode.success.description' })}
          </p>
          <Button style="primary" onClick={() => onClose && onClose()}>
            {formatMessage({ id: 'subscriptionCode.success.button' })}
          </Button>
        </>
      ) : errorActivateCoupon === 'this promotion code has expired' ? (
        <>
          <div className="flex justify-center">
            <div className="h-10 w-10 flex justify-center items-center mb-6 rounded-full bg-brand-neutral-800">
              <Icon name="discount" />
            </div>
          </div>
          <h2 className="title-h3 mb-1">
            {formatMessage({ id: 'subscriptionCode.expired.title' })}
          </h2>
          <h2 className="title-h3">
            {formatMessage({ id: 'subscriptionCode.expired.title.freeTrial' })}
          </h2>
          <p className="text-sm text-brand-neutral-500">
            {formatMessage({ id: 'subscriptionCode.expired.description' })}
          </p>
          <Button style="primary" onClick={handleSubmit}>
            {formatMessage({ id: 'subscriptionCode.expired.button' })}
          </Button>
        </>
      ) : (
        <Formik<FormValueType>
          innerRef={formikRef}
          initialValues={{ code: query.coupon ?? '' }}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({
            touched,
            errors,
            dirty,
            isSubmitting,
            isValid,
            handleSubmit,
            setFieldValue,
            setFieldError,
          }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              if (errorActivateCoupon) {
                setFieldValue('code', '');
                setFieldError('code', null);
              }
              // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [setFieldError, errorActivateCoupon]);

            return (
              <Form>
                <h2 className="title-h3 mb-0">{formatMessage({ id: 'subscriptionCode.title' })}</h2>
                <p className="text-sm text-brand-neutral-500">
                  {formatMessage({ id: 'subscriptionCode.description' })}
                </p>
                <div className="flex flex-col max-w-sm mt-10 m-auto">
                  <div>
                    <InputField
                      name="code"
                      placeholder={formatMessage({
                        id: 'subscriptionCode.form.placeholder.code',
                      })}
                      errors={errors.code}
                      touched={touched.code}
                      required
                      disabled={isLoadingActivateCoupon}
                    />

                    {errorActivateCoupon && (
                      <div className="flex items-center text-danger text-sm my-2">
                        {formatMessage({
                          id: `subscriptionCode.form.error.${errorActivateCoupon}`,
                        })}
                      </div>
                    )}
                  </div>
                  <Button
                    style="primary"
                    type={ButtonTypes.Submit}
                    onClick={handleSubmit}
                    ariaLabel={formatMessage({
                      id: 'subscriptionCode.form.accessibility.submit',
                    })}
                    disabled={!((dirty || isSubmitting) && isValid)}
                    className={classNames({ 'opacity-60': !dirty || !isValid })}
                  >
                    {isLoadingActivateCoupon ? (
                      <LoadingSpinnerIcon active />
                    ) : (
                      formatMessage({ id: 'subscriptionCode.form.submit' })
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};
