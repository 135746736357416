import { addAudioRoute } from '@/modules/audio/domain/routes/audioRoutes';
import { requestQuery } from '@/services/request';
import { Audio } from '@/types';

export const uploadAudio = async (data: { file: File }) => {
  if (!data.file) return;

  const formData = new FormData();
  formData.append('document', data.file);
  formData.append('voiceId', 'auto');

  return await requestQuery<Audio>({
    method: 'POST',
    url: addAudioRoute,
    body: formData,
  });
};
