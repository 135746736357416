import { useRouter } from 'next/router';

import { Icon } from './Icon';

interface BackProps {
  onClick?: () => void;
  className?: string;
}

export const Back = ({ onClick, className = '' }: BackProps) => {
  const { back } = useRouter();

  return (
    <button
      className={`absolute top-1 left-10 z-10 ${className}`}
      onClick={onClick || back}
      aria-label="retour"
    >
      <Icon name="chevron-left" className="back-button-icon" />
    </button>
  );
};
