/** @todo: move this file outside of the carouselsBlocks folder */
import { useIntl } from 'react-intl';
import SwiperCore, { A11y, Autoplay, FreeMode, Navigation, Pagination, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper } from 'swiper/react';
import { AutoplayOptions, PaginationOptions } from 'swiper/types';

import { Breakpoints } from '@/types';

interface SwiperBlockProps {
  onInit?: (swiper: SwiperCore) => void;
  onSlideChange?: (swiper: SwiperCore) => void;
  slidesPerView: number;
  spaceBetween?: number;
  slidesPerGroup?: number;
  slidesOffsetAfter?: number;
  slidesOffsetBefore?: number;
  navigation?: boolean;
  className?: string;
  breakpoints?: {
    [key in Breakpoints]?: {
      slidesPerView: number;
      spaceBetween?: number;
      slidesPerGroup?: number;
    };
  };
  centeredSlides?: boolean;
  loop?: boolean;
  pagination?: boolean | PaginationOptions;
  autoplay?: boolean | AutoplayOptions;
  freeMode?: boolean;
  containerRoleDescriptionMessage?: string;
  a11y?: {
    enabled: boolean;
    nextSlideMessage: string;
    prevSlideMessage: string;
    containerRoleDescriptionMessage: string;
  };
  scrollbar?: boolean;
  allowTouchMove?: boolean;
  children: React.ReactNode;
  autoHeight?: boolean;
}

export const SwiperBlock = ({ children, ...props }: SwiperBlockProps) => {
  const { formatMessage } = useIntl();

  const {
    onInit,
    onSlideChange,
    slidesPerView,
    spaceBetween,
    navigation,
    className,
    slidesOffsetAfter = 0,
    slidesOffsetBefore = 0,
    breakpoints,
    centeredSlides = false,
    loop = false,
    pagination = false,
    autoplay = false,
    freeMode = false,
    containerRoleDescriptionMessage,
    a11y = {
      enabled: true,
      nextSlideMessage: formatMessage({ id: 'swiper.accessibility.nextButton' }),
      prevSlideMessage: formatMessage({ id: 'swiper.accessibility.prevButton' }),
      containerRoleDescriptionMessage,
    },
    scrollbar = false,
    allowTouchMove = true,
    autoHeight = false,
  } = props;

  return (
    <Swiper
      onInit={onInit}
      onSlideChange={onSlideChange}
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      slidesOffsetAfter={slidesOffsetAfter}
      slidesOffsetBefore={slidesOffsetBefore}
      navigation={navigation}
      freeMode={freeMode}
      modules={[Pagination, Navigation, FreeMode, Autoplay, A11y, Scrollbar]}
      className={className}
      breakpoints={breakpoints}
      centeredSlides={centeredSlides}
      loop={loop}
      pagination={pagination}
      autoplay={autoplay}
      a11y={a11y}
      scrollbar={scrollbar}
      allowTouchMove={allowTouchMove}
      autoHeight={autoHeight}
    >
      {children}
    </Swiper>
  );
};
