import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useClickOutside } from '@/hooks/useClickOutside';
import { useWindowSize } from '@/hooks/useWindowSize';
import { AudioModal } from '@/modules/audio/presentation/modals/AudioModal';
import { AudioOnboardingModal } from '@/modules/audio/presentation/modals/AudioOnboardingModal';
import { useAuth } from '@/modules/auth/hooks/useAuth';
import { CouponModal } from '@/modules/subscriptions/presentation/modals/CouponModal';
import { Breakpoints, NavigationLink } from '@/types';
import {
  retrieveFeaturesSession,
  retrieveOnboardingSession,
  setOnboardingSession,
} from '@/utils/browserStorage';
import { historyRoute, homeRoute, hubRoute, searchRoute } from '@/utils/routes';

import { AccountNavigation } from './AccountNavigation';
import { Button } from './Button';
import { Icon } from './Icon';

export const SideBar = () => {
  const { formatMessage } = useIntl();
  const { route } = useRouter();
  const { user } = useAuth();
  const [navOpen, setNavOpen] = useState(false);
  const { screenWidth } = useWindowSize();
  const [isOpenAudioModal, setIsOpenAudioModal] = useState(false);
  const [isOpenAudioOnboardingModal, setIsOpenAudioOnboardingModal] = useState(false);
  const [isOpenCouponModal, setIsOpenCouponModal] = useState(false);

  const closeNavigation = useCallback(() => setNavOpen(false), [setNavOpen]);
  const navigationRef = useClickOutside(closeNavigation);

  const menuItems: NavigationLink[] = [
    {
      id: 'home',
      href: hubRoute,
      label: 'layout.nav.home',
      icon: 'discover-off',
      iconActive: 'discover',
      testId: 'home',
    },
    {
      id: 'search',
      href: searchRoute,
      label: 'layout.nav.search',
      icon: 'search-off',
      iconActive: 'search',
      testId: 'search',
    },
    {
      id: 'profile',
      href: historyRoute,
      label: 'layout.nav.profile',
      icon: 'profile-off',
      iconActive: 'profile-off',
      testId: 'profile',
    },
  ];

  useEffect(() => {
    if (screenWidth < Breakpoints.Medium) {
      closeNavigation();
    }
  }, [screenWidth, closeNavigation]);

  const isFileToAudioAllowed = retrieveFeaturesSession()?.fileToAudio;

  const toggleNavigation = useCallback(() => {
    setNavOpen(!navOpen);
  }, [navOpen, setNavOpen]);

  const handleAddAudio = () => {
    const hasSeenOnboarding = retrieveOnboardingSession()?.audio;

    if (!hasSeenOnboarding) {
      setIsOpenAudioOnboardingModal(true);
      setOnboardingSession({ audio: true });
      return;
    }
    setIsOpenAudioModal(true);
  };

  const handleCloseAudioModal = useCallback(() => {
    setIsOpenAudioModal(false);
  }, []);

  const handleCloseAudioOnboardingModal = useCallback(() => {
    setIsOpenAudioOnboardingModal(false);
    setIsOpenAudioModal(true);
  }, []);

  const handleOpenCouponModal = useCallback(() => {
    setIsOpenCouponModal(true);
  }, []);

  const handleCloseCouponModal = useCallback(() => {
    setIsOpenCouponModal(false);
  }, []);

  return (
    <>
      <div>
        <button
          onClick={toggleNavigation}
          className={classNames('navigation-toggle', { active: navOpen })}
        >
          <span className="sr-only">
            {formatMessage({ id: 'navigation.accessibility.web.toggle' })}
          </span>
          <Icon name="burger" />
        </button>
        <aside
          role="menu"
          ref={navigationRef}
          className={classNames('navigation', { open: navOpen })}
          aria-label={formatMessage({ id: 'navigation.accessibility.web' })}
        >
          <nav role="navigation">
            <Link
              href={homeRoute}
              onClick={toggleNavigation}
              aria-label={formatMessage({ id: 'navigation.accessibility.home' })}
              className="logo"
            >
              {user?.isCorporate ? (
                <Image src="/logo-majelan-pro.png" alt="Majelan Pro logo" width={160} height={30} />
              ) : (
                <Image src="/logo-majelan.png" alt="Majelan logo" width={150} height={30} />
              )}
            </Link>

            <ul className="main-navigation">
              {menuItems.map(({ id, href, label, iconActive, icon, hidden }) => {
                const isCurrentNav = route.split('/')[1] === href?.split('/')?.[1];

                return (
                  !hidden && (
                    <li key={id}>
                      <Link
                        href={href || ''}
                        onClick={toggleNavigation}
                        role="link"
                        aria-label={formatMessage(
                          { id: 'navigation.accessibility.navLink' },
                          { navLink: `${formatMessage({ id: label })}` }
                        )}
                        className={classNames({ active: isCurrentNav })}
                      >
                        <Icon name={isCurrentNav ? iconActive : icon} />

                        <span>{formatMessage({ id: label })}</span>
                      </Link>
                    </li>
                  )
                );
              })}
              {isFileToAudioAllowed && (
                <li>
                  <Button style="primary" onClick={handleAddAudio}>
                    <Icon name="plus" className="!me-0" />
                    {formatMessage({ id: 'layout.nav.addAudio' })}
                  </Button>
                </li>
              )}
            </ul>

            {!user?.isCorporate && !user?.premium && (
              <div className="promoCodeElement">
                <Icon name="discount" />

                <span className="promoCodeElementTitle">
                  {formatMessage({ id: 'layout.nav.promoCode.title' })}
                </span>

                <span className="promoCodeElementDescription">
                  {formatMessage({ id: 'layout.nav.promoCode.description' })}
                </span>

                <Button
                  onClick={handleOpenCouponModal}
                  aria-label={formatMessage({ id: 'navigation.accessibility.promoCode' })}
                  className="contents font-bold"
                >
                  {formatMessage({ id: 'layout.nav.promoCode.button' })}
                </Button>
              </div>
            )}

            <AccountNavigation handleOpenCouponModal={handleOpenCouponModal} />
          </nav>
        </aside>
      </div>
      {isOpenCouponModal && <CouponModal onClose={handleCloseCouponModal} />}
      {isOpenAudioModal && <AudioModal onClose={handleCloseAudioModal} />}
      {isOpenAudioOnboardingModal && (
        <AudioOnboardingModal onClose={handleCloseAudioOnboardingModal} />
      )}
    </>
  );
};
