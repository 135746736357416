import classNames from 'classnames';
import { ChangeEvent, DragEvent, useState } from 'react';

interface InputFileProps {
  children?: React.ReactNode;
  onChange: (file: File) => void;
  error: string;
  className?: string;
}

export const InputFile = ({ children, onChange, error, className }: InputFileProps) => {
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onChange(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      onChange(e.target.files[0]);
    }
  };

  return (
    <form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()} className="w-full">
      <input
        id="input-file-upload"
        type="file"
        multiple={true}
        onChange={handleChange}
        className="hidden"
      />
      <label
        htmlFor="input-file-upload"
        className={classNames(
          'w-full h-[240px] flex justify-center border border-dashed rounded-lg cursor-pointer bg-brand-neutral-800',
          { 'border-brand-neutral-300': dragActive },
          { 'border-brand-neutral-600': !dragActive },
          { 'border-danger': error },
          className
        )}
      >
        {children}
      </label>
      {dragActive && (
        <div
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          className="drag-file-element"
        />
      )}
    </form>
  );
};
